<template>
  <div>
    <Header
        v-model="headerLinks" v-bind:headerLinks="headerLinks"
    ></Header>

    <div class="container mt-2">

      <button class="btn btn-warning" style="border-radius: 12px;"><i class="fa fa-download"></i> Выгрузить отчет</button>
      <div class="row">
        <DatatableEventManagement :datatableId="'dt2'" :tableData="tableData" :columnsData="columnsData"/>
      </div>

    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
import DatatableEventManagement from "@/components/Datatables/DatatableEventManagement";

export default {
  name: "EventManagement",
  components: {
    Header,
    DatatableEventManagement
  },
  data () {
    return {
      headerLinks: [
        {
          name: "Просмотр событий",
          url: "/event_management"
        },
      ],
      tableData: [
        {
          id: 1,
          action_bo: "",
          action_fo: "Удаление пользователя",
          action_type: "Пользователи",
          datetime: "2021.07.21 03.24.56",
          level: "Высокий",
        },
        {
          id: 2,
          action_bo: "Выход из систему",
          action_fo: "",
          action_type: "Авторизация",
          datetime: "2021.07.23 20.54.56",
          level: "Низкий",
        },
        {
          id: 3,
          action_bo: "Вход в систему",
          action_fo: "",
          action_type: "Авторизация",
          datetime: "2021.07.23 10.24.56",
          level: "Низкий",
        },
      ],
      columnsData: [
        { title: "Действия пользователей BO", visible: true },
        { title: "Действия пользователей FO", visible: true },
        { title: "Тип события", visible: true },
        { title: "Дата и время", visible: true },
        { title: "Уровень критичности", visible: true },
      ]
    }
  }
}
</script>

<style scoped>

</style>