<template>
  <div>
    <div class="table_margin mb-2 mt-3">
      <div class="row">
        <div class="col-md-8"></div>
        <div class="col-md-2"><xlsxDownLoader :sheets="xlsxData2"/></div>
        <!-- <div class="col-md-2"> -->
          <!-- <input type="text" class="form-control" style="border-radius: 12px;" id="pfi1" v-model="search" placeholder="Поиск" @input="searchData"/> -->
        <!-- </div> -->
      </div>
      <table class="table table-hover">
        <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index">
            <a v-if="column.visible" @click="sortColumn(column, index)">{{ column.title }}
              <template v-if="column.sort">
                <i v-if="column.sortAsc" class="fa fa-sm fa-arrow-down"></i>
                <i v-else class="fa fa-sm fa-arrow-up"></i>
              </template>
            </a>
            <template v-if="column.search">
              <input type="text" class="form-control" style="border-radius: 12px;" v-model="column.searchValue" placeholder="Поиск" @input="searchColumnData(column, columns )"/>
            </template>
          </th>
        </tr>
        </thead>
      <tbody>
      <tr v-for="user in users" :key="user.id">
        <td>{{user.client_name}}</td>
        <td>{{user.inn}}</td>
        <td>{{formatDate(user.datetime)}}</td>
        <td>{{user.sa}}</td>
        <td>{{user.vc}}</td>
        <td>{{user.asr}}</td>
        <td>{{user.tts}}</td>
      </tr>
      </tbody>
    </table>
    </div>

    <div v-if="modalUsers" class="modal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Подсказка</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Статистика по загруженным пользователем данным</p>
            </div>
          </div>
        </div>

    </div>

  </div>
</template>

<script>
import searchTable from "@/utils/dataUtils";
import xlsxDownLoader from "@/components/xlsxDownLoader";

export default {
  name: "DatatableLicenseUsage",
  components: {
    xlsxDownLoader
  },
  props: {
    datatableId: String,
    tableData: [],
    columnsData: []
  },
  data() {
    return {
      search: '',
      origData: [],
      users: [],
      columns: [],
      demoData: [['0','1','2','3','4','5','6','7','8','9','10','11','12']],
      modalUsers: false,
      modalGroups: false,
      xlsxData: [],
      xlsxData2: []
    }
  },
  mounted(){
    this.users = this.tableData
    this.origData = this.tableData
    this.demoData = []
    this.columnsData.forEach(value => {
      const newValue = value
      newValue.sortAsc = true
      newValue.searchValue = ''
      this.columns.push(newValue)
    })
    console.log("m1")
    console.log("users", this.users)
    this.generateExportData(this.users)
  },
  methods: {
    genRep() {
      this.xlsxData2 = this.xlsxData
    },
    formatDate(dateData) {
      let today = new Date(dateData)
      // today.toISOString().split('T')[0]
      return today.toLocaleDateString('en-GB')
    },
    generateExportData(array) {
      let preparedData = []
      if (array) {
        array.forEach(rowValue => {
          preparedData.push({
            "Клиент": rowValue.client_name,
            "ИНН": rowValue.inn,
            "Контракт": rowValue.contract,
            "Дата": rowValue.datetime,
            "Речевая аналитика": rowValue.sa,
            "Виртуальный оператор": rowValue.vc,
            "Распонавание речи": rowValue.asr,
            "Синтез речи": rowValue.tts,
          })
        })
        // console.log("123")
        this.xlsxData.push({name: "RecordsReport", data: preparedData})
        // console.log("321")
      }
    },
    searchData() {
      if (this.search) {
        this.users = searchTable(this.users, this.search, '');
      } else {
        this.users = this.origData
      }
      // console.log("m2")
      this.generateExportData(this.users)
    },
    searchColumnData(column, columns) {
      if (column.searchValue) {
        this.users = searchTable(this.users, column.searchValue, column.key);
      } else {
        let m = false
        columns.forEach(value => {
          if (value.searchValue) {
            m = true
          }
        })
        if (!m) {
          this.users = this.origData
        }
      }
      if (this.users) {
        // console.log("m3")
        this.generateExportData(this.users)
      }
    },
    sortColumn(key, index) {
      if (key.sort) {
        if (this.columns[index].sortAsc) {
          this.sortAscending(key.key)
        } else {
          this.sortDescending(key.key)
        }
        const sortDirection = this.columns[index].sortAsc
        this.columns.forEach(value => {
          value.sortAsc = true
        })
        this.columns[index].sortAsc = !sortDirection
      }
      this.generateExportData(this.users)
    },
    sortAscending(key) {
      this.users.sort((a,b)=> (a[key] > b[key] ? 1 : -1))
    },
    sortDescending(key) {
      this.users.sort((a,b)=> (a[key] < b[key] ? 1 : -1))
    },

    openReport(reportData) {

      if (!reportData) {
        console.log('')
      }
      // this.$router.push({
      //   name: 'SaMainReport',
      //   params: {
      //     name: reportData.client_name
      //   }
      // })
    }
  }
}
</script>

<style scoped>

.table_margin {
  margin-left: 15px;
  margin-right: 15px;
}

</style>