<script>
// import { Bar } from 'vue-chartjs'
import {defineComponent} from 'vue'
import {Bar} from 'vue3-chart-v2'

export default defineComponent({
  extends: Bar,
  props: {
    extData: {
      label: String,
      labelsData: [],
      data: []
    }
  },
  data: () => ({
    chartdata: {
      labels: ['January', 'February', '333', '444'],
      datasets: [
        {
          label: 'Data Queue',
          // backgroundColor: '#f87979',
          data: [30, 75, 40, 50],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        },
        {
          label: 'Data Queue',
          // backgroundColor: '#f87979',
          data: [30, 75, 40, 50],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)'
          ],
          borderColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)'
          ],
          borderWidth: 1
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      // scales: {yAxes: [{ticks: {beginAtZero: true}}]},
      scales: {xAxes: [{stacked: true, gridLines: {display: false}}], yAxes: [{stacked: true, gridLines: {display: false}, ticks: {beginAtZero: true}}]},
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.value + ' %';
          }
        },
      },
      elements: {
        point:{
          radius: 0
        }
      }
    }
  }),
  mounted () {
    console.log("STACK MOUNTED")
    console.log("labelsData", this.extData.labelsData)
    console.log("extData", this.extData.data)

    console.log("extData 0", this.extData.data[0])
    console.log("extData 1", this.extData.data[1])
    //
    // this.chartdata.labels = this.extChData.labelsData
    this.chartdata.datasets[0].data = this.extData.data[0]
    this.chartdata.datasets[1].data = this.extData.data[1]
    // this.chartdata.datasets[0].label = this.extData.label
    // this.chartdata.datasets[1].label = this.extData.label

    this.chartdata.datasets[0].label = 'Входящие'
    this.chartdata.datasets[1].label = 'Исходящие'

    this.chartdata.labels = this.extData.labelsData
    // this.chartdata.labels = this.extData.data

    // console.log("this.chartdata mounted", this.chartdata)

    this.renderChart(this.chartdata, this.options)
  },
})
</script>
<style>
</style>