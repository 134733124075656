<template>
  <div>
    <Header
        v-model="headerLinks" v-bind:headerLinks="headerLinks"
    ></Header>

    <div class="container-fluid mt-2">
      <DatePickerMulti @setData="filterDate"/>

      <div class="mb-3 mt-3">
        <div class="row">
          <ChartWrapper :extData="rep1" :barType="'bar'" v-if="chartLoaded"/>
          <ChartWrapperWide :extData="rep2" :barType="'bar'" v-if="chartLoaded"/>
        </div>
      </div>

      <div class="row">
        <DatatableLicenseUsage :datatableId="'dt2'" :tableData="tableData" :columnsData="columnsData" v-if="chartLoaded"/>
      </div>

    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
import DatatableLicenseUsage from "@/components/Datatables/DatatableLicenseUsage";
import DatePickerMulti from "@/components/DatePickerMulti";
import ChartWrapper from "@/components/Charts/ChartWrapper";
import ChartWrapperWide from "@/components/Charts/ChartWrapperWide";
import {TLicences} from "@/utils/coreApi";

export default {
  name: "LicenseUsageInfo",
  components: {
    Header,
    DatatableLicenseUsage,
    DatePickerMulti,
    ChartWrapper,
    ChartWrapperWide
  },
  data () {
    return {
      headerLinks: [
        {
          name: "Отчетность по использованию лицензий",
          url: "/license_usage_info"
        },
      ],
      tableData: [],
      tableData2: [
        {
          id: 1,
          client_name: "ООО Рога и копыта",
          datetime: "2021.07.21",
          sa: 56,
          vc: 23,
          asr: 88,
          tts: 45,
        },
        {
          id: 2,
          client_name: "ООО Новый век",
          datetime: "2021.07.22",
          sa: 12,
          vc: 21,
          asr: 54,
          tts: 23,
        },
        {
          id: 3,
          client_name: "ООО Проверка",
          datetime: "2021.07.23",
          sa: 34,
          vc: 77,
          asr: 56,
          tts: 12,
        },
      ],
      columnsData: [
        { title: "Клиент", visible: true, key: 'client_name', sort: true, search: true },
        { title: "ИНН", visible: true, key: 'inn', sort: true, search: true },
        { title: "Дата", visible: true, key: 'datetime', sort: true, search: true },
        { title: "Речевая аналитика", visible: true, key: 'sa', sort: true, search: true },
        { title: "Виртуальный оператор", visible: true, key: 'vc', sort: true, search: true },
        { title: "Распознавание речи", visible: true, key: 'asr', sort: true, search: true },
        { title: "Синтез речи", visible: true, key: 'tts', sort: true, search: true },
      ],
      rep1: {
        label: "Лицензии общее количество",
        labelsData: ['Речевая аналитика', 'Виртуальный оператор', 'Распознавание речи', 'Синтез речи'],
        data2: [10,20,30,40],
        data: []
      },
      rep2: {
        label: "Топ 10 Клиентов Речевая аналитика",
        // labelsData: ['Речевая аналитика', 'Синтез речь', 'Распознавание речи', 'Виртуальный оператор'],
        // data: [10,20,30,40]
        labelsData2: ['Речевая аналитика', 'Синтез речь', 'Распознавание речи', 'Виртуальный оператор',
          'Речевая аналитика', 'Синтез речь', 'Распознавание речи', 'Виртуальный оператор', 'Распознавание речи', 'Виртуальный оператор'],
        labelsData: [],
        data2: [10,20,30,40,10,20,30,40,50,60],
        data: [],
      },
      chartLoaded: false
    }
  },
  methods: {
    async getData(date_from, date_to) {
      this.chartLoaded = false
      console.log("date_from, date_to", date_from, date_to)
      let licenscesObj = new TLicences()
      let licData = await licenscesObj.getFilterDate(date_from, date_to)
      console.log("licData", licData)
      if (licData) {
        // this.rep1.data = []
        // this.rep2.labelsData = []
        // this.rep2.data = []
        // this.tableData = []

        this.rep1.data = licData.total_licences.total_arr
        this.rep2.labelsData = licData.top_10_licences.sa.labelsData
        this.rep2.data = licData.top_10_licences.sa.data
        this.tableData = licData.table_licenses
        this.chartLoaded = true
      }
    },
    async filterDate (dateObj) {
      console.log("dateObj", dateObj)
      await this.getData(dateObj.date_from, dateObj.date_to)
    },
  },
  async mounted() {
    let date_to = new Date();
    let date_from = ( d => new Date(d.setDate(d.getDate()-30)) )(new Date);
    await this.getData(date_from, date_to)
  }
}
</script>

<style scoped>

</style>