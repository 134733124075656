<template>
  <div class="row">
    <div class="col-9">
      <div class="d-inline-block" style="margin-right:15px;">
        <a class="text-secondary text-decoration-none">Дата начала: </a>
        <Datepicker class="d-inline-block" style="border-radius: 8px;" v-model="dateFrom" />
      </div>
      <div class="d-inline-block" style="margin-right:15px;">
        <a class="text-secondary text-decoration-none">Дата окончания: </a>
        <Datepicker class="d-inline-block" style="border-radius: 8px;" v-model="dateTo" />
      </div>
      <div class="d-inline-block">
        <btn class="btn btn-sm btn-warning" style="border-radius: 10px;" @click="setData">Выбрать</btn>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'
const picked = ref(new Date())
const picked2 = ref(( d => new Date(d.setDate(d.getDate()-30)) )(new Date));

export default {
  name: "DatePickerMulti",
  components: {
    Datepicker
  },
  data() {
    return {
      dateTo: picked,
      dateFrom: picked2
    }
  },
  methods: {
    setData () {
      const dateObj = {
        date_from: this.dateFrom,
        date_to: this.dateTo,
      }
      this.$emit('setData', dateObj)
    }
  }
}
</script>

<style scoped>

</style>