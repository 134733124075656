<template>
  <div>
    <section>
<!--      <div>Sheets: {{ sheets }}</div>-->
      <xlsx-workbook>
        <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
        />
        <xlsx-download>
          <button class="btn btn-outline-success" @click="$parent.genRep"><i class="fa fa-sm fa-download"></i> Отчет в Excel</button>
        </xlsx-download>
      </xlsx-workbook>
    </section>
  </div>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue3-xlsx";


export default {
  name: "xlsxDownloader",
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: {
    sheets: []
  },
  data() {
    return {
      file: null,
      filesXlsx: [],
      selectedSheet: null,
      loading: true,
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
  }
};
</script>