<template>

  <div class="card ml-2 mr-2 mt-2" style="height: 350px;max-height: 385px;width: 340px;margin-left:1%;padding-right:1%;">
    <div class="card-body">
      <!--        <p class="card-title d-inline">{{// chart.name}}</p>-->
      <p class="card-title d-inline">{{extDataS.label}}</p>
      <br>
      <div class="text-center">
        <!--          <DemoChart style="height: 250px;max-height: 280px;width: 66%;margin-left:2%;padding-right:2%;display:inline-block;"></DemoChart>-->
        <BarChart v-bind:extData="extDataS" v-if="load_bar"/>
        <BarStackChart v-bind:extData="extDataS" v-if="load_bar_stack"/>
        <LineChart v-bind:extData="extDataS" v-if="load_line"/>
        <DoughnutChart v-bind:extData="extDataS" v-if="load_doughnut"/>
        <PieChart v-bind:extData="extDataS" v-if="load_pie"/>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/Charts/BarChart.vue'
import BarStackChart from '@/components/Charts/BarStackChart.vue'
import LineChart from '@/components/Charts/LineChart.vue'
import DoughnutChart from '@/components/Charts/DoughnutChart.vue'
import PieChart from '@/components/Charts/PieChart.vue'

export default {
  name: "ChartWrapper",
  components: {
    BarChart,
    LineChart,
    DoughnutChart,
    PieChart,
    BarStackChart
  },
  props: {
    extData: {
      label: String,
      labelsData: [],
      data: [],
      stack: Boolean,
    },
    barType: String
  },
  data() {
    return {
      load_bar: false,
      load_line: false,
      load_doughnut: false,
      load_pie: false,
      load_bar_stack: false,
      // load_demo: false,
      extDataS: {
        label: String,
        labelsData: [],
        data: [],
      }
    }
  },
  mounted() {
    if (this.barType === 'bar') {
      this.load_bar = true
    }
    if (this.barType === 'bar_stack') {
      this.load_bar_stack = true
    }
    if (this.barType === 'line') {
      this.load_line = true
    }
    if (this.barType === 'doughnut') {
      this.load_doughnut = true
    }
    if (this.barType === 'pie') {
      this.load_pie = true
    }
    this.extDataS.labelsData = this.extData.labelsData
    this.extDataS.data = this.extData.data
    this.extDataS.label = this.extData.label
  }
}
</script>

<style scoped>

</style>