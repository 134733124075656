<template>
  <div>
    <Header
        v-model="headerLinks" v-bind:headerLinks="headerLinks"
    ></Header>

    <div class="container mt-2">

<!--      <button class="btn btn-secondary"><i class="fa fa-plus"></i> Добавить</button>-->
<!--      <button class="btn btn-secondary float-end"><i class="fa fa-user"></i> Перейти в ЛК Клиента</button>-->

      <div class="row">
        <div class="container mt-3">
        <select class="form-select form-select" style="border-radius: 12px;" aria-label=".form-select-sm example">
          <option selected>Скорость обработки</option>
          <option value="1">Высокая</option>
          <option value="2">Средняя</option>
        </select>
        </div>
      </div>

      <div class="row">
        <div class="container mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Длительность паузы</label>
                <input type="number" class="form-control" style="border-radius: 12px;" id="exampleFormControlInput1" placeholder="Длительность паузы">
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Максимальное количество каналов</label>
                <input type="number" class="form-control" style="border-radius: 12px;" id="exampleFormControlInput2" placeholder="Максимальное количество каналов">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Объем загруженных/синтезированных файлов</label>
                <input type="number" class="form-control" style="border-radius: 12px;" id="exampleFormControlInput3" placeholder="Объем загруженных/синтезированных файлов">
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Максимальное количество номеров для переадресации</label>
                <input type="number" class="form-control" style="border-radius: 12px;" id="exampleFormControlInput4" placeholder="Максимальное количество номеров для переадресации">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Период хранения записей</label>
                <input type="number" class="form-control" style="border-radius: 12px;" id="exampleFormControlInput5" placeholder="Период хранения записей">
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Файл оповещения о записи разговоров</label>
                <select class="form-select form-select" style="border-radius: 12px;" aria-label=".form-select-sm example">
                <option selected>Файл оповещения о записи разговоров</option>
                <option value="1">Файл 1</option>
                <option value="2">Файл 2</option>
              </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container mt-3">
          <div class="row">
            <div class="col-md-6">
              <select class="form-select form-select" style="border-radius: 12px;" aria-label=".form-select-sm example">
                <option selected>Голос</option>
                <option value="1">Параметр 1</option>
                <option value="2">Параметр 2</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="form-select form-select" style="border-radius: 12px;" aria-label=".form-select-sm example">
                <option selected>Пол </option>
                <option value="1">Параметр 1</option>
                <option value="2">Параметр 2</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container mt-3">
          <div class="row">
            <div class="col-md-6">
              <select class="form-select form-select" style="border-radius: 12px;" aria-label=".form-select-sm example">
                <option selected>Эмоциональность</option>
                <option value="1">Параметр 1</option>
                <option value="2">Параметр 2</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="form-select form-select" style="border-radius: 12px;" aria-label=".form-select-sm example">
                <option selected>Список мобильных тарифных планов</option>
                <option value="1">План 1</option>
                <option value="2">План 2</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-warning mt-3" style="border-radius: 12px;"><i class="fa fa-save"></i> Сохранить</button>

      <!--      <div class="row">-->
<!--        <Datatable :datatableId="'dt1'"/>-->
<!--      </div>-->

    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
// import Datatable from "@/components/Datatable";

export default {
  name: "DefaultVCPreferences",
  components: {
    Header,
    // Datatable
  },
  data () {
    return {
      headerLinks: [
        {
          name: "Настройка параметров «Виртуального оператора» по-умолчанию",
          url: "/default_vc_preferences"
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>