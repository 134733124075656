<script>
import { Pie } from 'vue3-chart-v2'
import {defineComponent} from "vue";

export default defineComponent({
  extends: Pie,
  props: {
    extData: {
      label: String,
      labelsData: [],
      data: []
    }
  },

  data: () => ({
    chartdata: {
      labels: ['January', 'February', '333', '444'],
      datasets: [
        {
          label: 'Data Queue',
          // backgroundColor: '#f87979',
          data: [30, 75, 40, 50],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      scales: {yAxes: [{ticks: {beginAtZero: true}}]},
    }
  }),

  mounted () {

    // console.log(this.extData.labelsData)
    // console.log(this.extData.data)
    //
    // this.chartdata.labels = this.extChData.labelsData
    this.chartdata.datasets[0].data = this.extData.data
    this.chartdata.datasets[0].label = this.extData.label

    this.chartdata.labels = this.extData.labelsData
    // this.chartdata.labels = this.extData.data

    // console.log("this.chartdata mounted", this.chartdata)

    this.renderChart(this.chartdata, this.options)
  },
})
</script>

<style>
</style>